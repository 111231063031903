/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Ghalia Adzana
==============================================================================================*/
@import "sass-lib";
// @import url('../fonts/stylesheet.css');
@font-face {
  font-family: 'Olde English';
  src: url('../fonts/OldeEnglish-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
body {
    color: #333;
    font-size: 12px;
    font-family: $f_f1;
    position: relative;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
}

p {
    margin: 1px 0 15px;
    line-height: 20px;
}

input,
textarea,
select {
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=checkbox],
input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

/* structure 
----------------------------------------------------------------------------------------------*/
section {
    position: relative;
}

.wrapper {
    width: 1170px;
    margin: 0 auto;
}

/* global
----------------------------------------------------------------------------------------------*/
.after_clear:after,
.wrapper:after,
section:after {
    clear: both;
    display: block;
    content: " "
}

.before_clear:before {
    clear: both;
    display: block;
    content: " "
}

.a-left {
    text-align: left
}

.a-right {
    text-align: right
}

.a-center {
    text-align: center
}

.italic {
    font-style: italic;
}

.bold {
    font-weight: bold;
}

.regular {
    font-weight: lighter;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.clear {
    clear: both;
}

.btn {
    display: inline-block;
    position: relative;
    font-size: 14px;

    &.slide {
        background: transparent;
        @include borderRadius(10px);
        padding: 20px 45px;
        border: 2px solid #fff;
        color: #fff;
        display: inline-block;
        @include transition(0.15s linear all);
    }

    &.load-more {
        padding: 20px 55px;
        font-size: 14px;
        border: 2px solid #fff;
        background: #1b2287;
        color: #fff;
        border: none;
        font-weight: 700;
        margin: 0 auto;

        &.mid {
            text-align: center;
            margin: 0 auto;
        }
    }

    &.apply {
        padding: 15px 75px;
        background: #1b2287;
        color: #fff;
        font-weight: 700;
        margin-top: 50px;
        font-size: 18px;
    }

    &.start {
        padding: 15px 30px 15px 80px;
        background: #1b2287;
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 100px;
        margin-top: 50px;

        &:before {
            content: '';
            background: url('../images/material/ico-virtual-s.png')no-repeat center;
            width: 34px;
            height: 24px;
            position: absolute;
            top: 13px;
            left: 30px;
        }
    }

    &.yell {
        padding: 15px 10px;
        background: #ffad00;
        @include borderRadius(8px);
        margin-top: 25px !important;
    }
}

.btn-scroll {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 57px;
    display: block;
    background: #e1e1e1;
    margin: auto;

    span {
        padding: 10px 0 50px;
        display: block;
        text-align: center;
        background: url('../images/material/ic-top.png') no-repeat center 85%;
        font-size: 14px;
        font-weight: 600;
        color: #949494;
        text-transform: uppercase;
    }
}

.wrapper {
    width: 1360px;
    margin: 0 auto;
}

.btn-share {
    position: absolute;
    right: 960px;
    top: 0;
    background: #d8d8d8;

    span {
        background: url('../images/material/ic-share.png') no-repeat 20%;
        ;
        display: block;
        padding-left: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 20px;
        color: #666666;
    }
}

/* header
----------------------------------------------------------------------------------------------*/
header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 444;
    transition: .2s all ease-out;
    .top {
        position: relative;
        background-color: #011a3b;
        height: 45px;
        width: 100%;
        z-index: 5;

        .search {
            float: right;
            padding: 14px 0;
            position: relative;
            padding-left: 30px;
            margin-left: 23px;

            &:before {
                content: '';
                display: block;
                width: 1px;
                height: 25px;
                background: #fff;
                opacity: 0.7;
                position: absolute;
                left: 0;
                top: 10px;
                bottom: 0;
            }

            a {
                text-indent: -999999px;
                display: inline-block;
                position: relative;
                background: url('../images/material/ico-search.png')no-repeat center;
                width: 15px;
                height: 15px;
            }

            &:after {
                display: block;
                content: '';
                clear: both;
            }

            .search-drop {
                position: absolute;
                top: 55px;
                right: -15px;
                width: 240px;
                background: #eaeaea;
                padding: 5px;
                @include borderRadius(7px);
                z-index: 9;
                @include boxShadow(0px 0px 15px -4px #666);
                display: none;

                input {
                    width: 100%;
                    border: none;
                    @include borderRadius(5px);
                    font-size: 14px;
                    background: #FFF url(../images/material/search-ico-2.png) no-repeat 94% center;
                    @include boxSizing(border-box);
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: -10px;
                    border-bottom: 10px solid #eaeaea;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    right: 13px;
                }
            }
        }

        .lang {
            float: right;
            background: url('../images/material/ic-arr-dwn.png')no-repeat right;
            padding-right: 20px;
            position: relative;

            &:after {
                display: block;
                content: '';
                clear: both;
            }

            span {
                line-height: 45px;
                color: #fff;

                &:before {}
            }

            .drop {
                position: absolute;
                background: #FFF;
                width: 40px;
                height: auto;
                top: 100%;
                padding: 15px 10px;
                @include boxShadow(0px 0px 5px -3px);
                visibility: hidden;
                opacity: 0;
                @include transition(0.2s linear all);

                a {
                    display: block;
                    border-bottom: 1px solid #CCC;
                    padding: 0 0 10px 0;
                    margin-bottom: 10px;
                    font-weight: bold;
                    color: #161b66;

                    &:last-child {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border: none;
                    }

                    &:hover {
                        color: #ffad00;
                    }
                }
            }

            &:hover {
                .drop {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    .bot {
        position: relative;
        padding-bottom: 38px;
        padding-top: 10px;

        .wrapper {
            position: relative;
        }

        .left_header {
            padding-top: 0;
            position: absolute;
            left: 0;
            top: 0px;

            &:after {
                display: block;
                content: '';
                clear: both;
            }

            .logotop {
                width: 350px;
                transition: .2s all ease-out;
            }

            .logobot {
                display: none;
                width: 250px;
                transition: .2s all ease-out;

            }
        }

        .right_header {
            float: right;

            &:after {
                display: block;
                content: '';
                clear: both;
            }

            nav {
                >ul {
                    >li {
                        margin: 40px 25px 0 0;
                        float: left;
                        font-size: 15px;
                        font-weight: 600;
                        position: relative;

                        &:last-child {
                            margin-right: 0;
                        }

                        &:after {
                            display: block;
                            content: '';
                            clear: both;
                        }

                        a {
                            color: #fff;
                            text-transform: uppercase;
                            padding: 0 0 10px 0;
                        }
                        &.btn.yell{
                            a{
                                color: #000 !important;
                            }
                        }
                        ul {
                            @include transition(0.2s all ease-out)
                        }

                        >ul {
                            visibility: hidden;
                            position: absolute;
                            background: #161b66;
                            top: 40px;
                            opacity: 0;
                            position: absolute;
                            left: 50%;
                            @include transform(translateX(-50%));
                            @include transition(all ease-out 0.25s);
                            @extend .after_clear;

                            &:before {
                                content: '';
                                width: 0;
                                border-bottom: 10px solid #161b66;
                                border-left: 15px solid transparent;
                                border-right: 15px solid transparent;
                                display: block;
                                position: absolute;
                                top: -10px;
                                left: 0;
                                right: 0;
                                margin: auto;
                            }

                            li {
                                &.parent {
                                    >a {
                                        position: relative;
                                        padding-right: 30px;

                                        &:before {
                                            content: '';
                                            background: url('../images/material/arr-right-head.png');
                                            width: 8px;
                                            height: 13px;
                                            position: absolute;
                                            top: 24px;
                                            right: 20px;
                                        }
                                    }
                                }
                            }

                            >li {
                                float: none;
                                margin: 0;
                                position: relative;

                                &.active {
                                    >a {
                                        &:after {
                                            width: 4px;
                                        }
                                    }
                                }

                                a {
                                    display: block;
                                    padding: 20px;
                                    font-size: 15px;
                                    text-transform: none;
                                    border-bottom: 1px solid #262c85;
                                    width: 280px;
                                    position: relative;
                                    line-height: 20px;
                                    @include boxSizing(border-box);

                                    &:after {
                                        content: '';
                                        background: #ffbb2b;
                                        width: 0px;
                                        position: absolute;
                                        left: 0px;
                                        top: 0;
                                        bottom: 0;
                                        visibility: hidden;
                                    }

                                    &:hover {
                                        background: #121760;

                                        &:after {
                                            width: 4px;
                                            visibility: visible;
                                        }
                                    }
                                }

                                >ul {
                                    position: absolute;
                                    left: 100%;
                                    top: 0;
                                    bottom: auto;
                                    background: #121760;
                                    opacity: 0;
                                    visibility: hidden;

                                    li {
                                        position: relative;

                                        a {
                                            &:hover {
                                                background: #0d114e;
                                            }
                                        }

                                        ul {
                                            background: #0e1252;
                                            position: absolute;
                                            left: 100%;
                                            top: 0;
                                            bottom: 0;
                                            opacity: 0;
                                            visibility: hidden;

                                            li {
                                                position: relative;

                                                a {
                                                    &:hover {
                                                        background: #090d43;
                                                    }
                                                }
                                            }
                                        }

                                        &:hover {
                                            >ul {
                                                opacity: 1;
                                                visibility: visible;
                                            }
                                        }
                                    }
                                }

                                &:hover {
                                    >ul {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                            }
                        }

                        &:hover,
                        &.active {
                            >ul {
                                opacity: 1;
                                top: 35px;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }

    }

    .togglemenu {
        display: none;
        width: 25px;
        position: relative;
        float: right;
        margin: 50px 0 0 0;
        z-index: 899888;

        span {
            position: absolute;
            width: 100%;
            height: 3px;
            background: #fff;
            display: block;
            text-indent: -999999px;
            @include borderRadius(3px);
            @include transition(all .2s ease-in-out);

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                top: 9px;
            }

            &:nth-child(3) {
                top: 18px;
                width: 80%;
            }
        }
    }

    &.float {
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid #ddd;
        z-index: 898888;

        .top {
            display: none;
        }

        .bot {
            padding-top: 0;

            .left_header {
                padding-top: 10px;
                top: 10px;

                .logotop {
                    display: none;
                }

                .logobot {
                    display: block;
                }
            }

            .right_header {
                nav {
                    ul {
                        li {
                            padding-bottom: 32px;

                            a {
                                color: #666666;
                            }

                            &.yell {
                                padding-bottom: 15px;

                                a {
                                    color: #fff;
                                }
                            }

                            ul {
                                li {
                                    padding-bottom: 0;

                                    a {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .togglemenu {
            margin-top: 22px;

            span {
                background: #192183;
            }
        }
    }

    &.actmob {
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid #ddd;
        height: 80px;
        z-index: 898888;

        .top {
            display: none;
        }

        .bot {
            .left_header {
                padding-top: 0;

                .logotop {
                    display: none;
                }

                .logobot {
                    display: block;
                }

                a {
                    width: 100%;
                }
            }

            .right_header {
                nav {
                    ul {
                        li {
                            padding-bottom: 32px;

                            a {
                                color: #666666;
                            }

                            &.yell {
                                padding-bottom: 15px;

                                a {
                                    color: #fff;
                                }
                            }

                            ul {
                                li {
                                    padding-bottom: 0;

                                    a {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .togglemenu {
            margin-top: 25px;

            span {
                background: #192183;

                &:nth-child(1) {
                    width: 100%;
                    top: 10px;
                    @include transform(rotate(-45deg));
                }

                &:nth-child(2) {
                    display: none;
                }

                &:nth-child(3) {
                    width: 100%;
                    top: 10px;
                    @include transform(rotate(45deg));
                }
            }
        }
    }

}

body.lock {
    overflow: hidden;
}

.menumobile {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #1b2287;
    z-index: 88888;
    padding: 80px 0 0 0;
    display: none;
    opacity: 0;
    @include boxSizing(border-box);
    @include transform(scale(1.1));

    .navacc {
        overflow: auto;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 80px 0 155px 0;
        top: 0;
        @include boxSizing(border-box);

        ul {
            li {
                border-bottom: 1px solid #262c85;

                a {
                    font-size: 16px;
                    color: #fff;
                    padding: 27px 45px 25px 25px;
                    display: block;
                    position: relative;

                    &.parent {
                        font-weight: 500;

                        &:before {
                            position: absolute;
                            width: 3px;
                            height: 0;
                            left: 0;
                            bottom: 2px;
                            content: "";
                            background: #ffbb2b;
                            @include transition(all .15s ease-in-out);
                        }

                        &:after {
                            position: absolute;
                            width: 12px;
                            height: 7px;
                            content: "";
                            right: 20px;
                            top: 30px;
                            background: url('../images/material/ic-arr-dwn-g.png') no-repeat 0 0;
                        }

                        &.expanded {
                            &:before {
                                height: 75%;
                            }
                        }
                    }
                }

                ul {
                    padding: 10px 0 25px 0;
                    background: #171e77;
                    display: none;

                    li {
                        border: 0;

                        a {
                            font-size: 16px;
                            color: #b3b4c9;
                            line-height: 20px;
                            padding: 8px 35px;
                        }

                        &.subchild {
                            position: relative;

                            >a {
                                &:after {
                                    position: absolute;
                                    width: 12px;
                                    height: 7px;
                                    content: "";
                                    right: 20px;
                                    top: 15px;
                                    background: url('../images/material/ic-arr-dwn-g.png') no-repeat 0 0;
                                }
                            }

                            >ul {
                                display: none;
                                padding-left: 20px;

                                li {
                                    >ul {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .footmob {
        position: fixed;
        width: 100%;
        height: 155px;
        left: 0;
        bottom: 0;
        padding-bottom: 0;
        background: #1b2287;

        .row {
            margin: 10px 0 20px 0;
        }

        .lang-mob {
            background: url('../images/material/ic-arr-dwn.png')no-repeat right;
            padding-right: 20px;
            position: relative;

            &:after {
                display: block;
                content: '';
                clear: both;
            }

            span {
                line-height: 45px;
                color: #fff;

                &:before {}
            }

            .drop {
                position: absolute;
                background: #FFF;
                width: 40px;
                height: auto;
                top: 100%;
                padding: 15px 10px;
                @include boxShadow(0px 0px 5px -3px);
                visibility: hidden;
                opacity: 0;
                @include transition(0.2s linear all);

                a {
                    display: block;
                    border-bottom: 1px solid #CCC;
                    padding: 0 0 10px 0;
                    margin-bottom: 10px;
                    font-weight: bold;
                    color: #161b66;

                    &:last-child {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border: none;
                    }

                    &:hover {
                        color: #ffad00;
                    }
                }
            }

            &:hover {
                .drop {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        .search-mob {
            padding: 14px 0;
            position: relative;
            padding-left: 30px;
            margin-left: 23px;

            a {
                text-indent: -999999px;
                display: inline-block;
                position: relative;
                background: url('../images/material/ico-search.png')no-repeat center;
                width: 15px;
                height: 15px;
            }

            &:after {
                display: block;
                content: '';
                clear: both;
            }

            .search-drop {
                position: absolute;
                top: 0px;
                right: 30px;
                width: 240px;
                background: #eaeaea;
                padding: 5px;
                @include borderRadius(7px);
                z-index: 9;
                @include boxShadow(0px 0px 15px -4px #666);
                display: none;

                input {
                    width: 100%;
                    border: none;
                    @include borderRadius(5px);
                    font-size: 14px;
                    background: #FFF url(../images/material/search-ico-2.png) no-repeat 94% center;
                    @include boxSizing(border-box);
                }

                /* &:after{
                    content:"";position:absolute;top:-10px;border-bottom:10px solid #eaeaea;border-left:10px solid transparent;border-right:10px solid transparent;
                    right:13px;
                } */
            }
        }

        .enroll-mob {
            display: block;
            height: 40px;
            line-height: 40px;
            color: #333;
            font-size: 14px;
            padding: 0 20px;
            font-weight: bold;
            background: #ffad00;
            text-align: center;
            @include borderRadius(4px);
        }
    }
}


/* homepages
----------------------------------------------------------------------------------------------*/
.banner-top {
    padding: 180px 0px 100px;
    background: linear-gradient(180deg, rgba(1,26,59,1) 0%, rgba(3,61,140,1) 100%);
    height: calc(100vh - 250px);
    overflow: hidden;
    .wrap-box {
        display: flex;
        flex-flow: row wrap;
        margin: -12px;
        align-items: stretch;
        height:calc(100vh - 200px);
        overflow: hidden;
        .inner {
            height: 100%;
        }

        figure {
            margin: 0;
            border-radius: 50px 0px 50px 0px;
            overflow: hidden;
            height: 100%;
            position: relative;

            &:hover {
                figcaption {
                    opacity: 1;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
            }

            figcaption {
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                width: auto;
                height: 100%;
                background: linear-gradient(0deg, rgba(21, 27, 101, 0.53), rgba(21, 27, 101, 0.53));
                color: #fff;
                display: flex;
                align-items: flex-end;
                padding: 38px;
                box-sizing: border-box;
                font-size: 21px;
                line-height: 1.4;
                opacity: 0;
                transition: .2s all ease-out;
            }
        }

        h2 {
            font-size: 40px;
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            &.fonts-oldenglish{
                font-family: 'Olde English';
                font-weight: normal;
                text-transform: capitalize;
                font-size: 50px;
                line-height: 40px;
            }
        }
       
        .box {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 12px;
            box-sizing: border-box;
            height: 50%;
            max-height: 365px;
            &:first-child {
                align-self: flex-start;
                padding-left: 40px;

                h2 {
                    text-align: right;
                    margin-bottom: 15px;
                }
            }

            &:last-child {
                align-self: flex-start;
                padding-right: 40px;

                h2 {
                    margin-top: 15px;
                }
            }
            &:first-child,&:last-child{
                figure{
                    height: calc(100% - 55px);
                }
            }
        }
    }
}

.banner-slider {
    .list-slider {
        position: relative;

        .image-slide {
            height: 100vh;
            overflow: hidden;

            &:before {
                background: url('../images/material/banner-shd.png')no-repeat center;
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                top: 0;
                content: '';
                z-index: 3;
                background-size: cover;
            }

            img {
                display: block;
                width: 100vw;
                height: 100vh;
                object-fit: cover;
            }
        }

        .wrapper {
            position: relative;
        }

        .box-caption {
            width: 670px;
            position: absolute;
            left: 0;
            bottom: 100px;
            z-index: 4;
            top: auto;

            .tittle-capt {
                width: 100%;
                height: 100px;
                color: #fff;
                padding-left: 45px;
                border-left: 5px solid #ffbb2b;

                i {
                    font-weight: 500i;
                    font-size: 24px;
                }

                h1 {
                    font-size: 56px;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }

            p {
                font-family: $f_f2;
                font-size: 18px;
                line-height: 30px;
                color: #fff;
            }
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 20px;
        width: 1360px;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: right;

        li {
            button {
                background: transparent;
                @include borderRadius(100%);
                border: 1px solid transparent;

                &:before {
                    background: #fff;
                    @include borderRadius(100%);
                    width: 6px;
                    height: 6px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    opacity: 1;
                }
            }

            &.slick-active {
                button {
                    border-color: #fff;
                }
            }
        }
    }
}

.box-share-right {
    position: fixed;
    top: 40%;
    width: 55px;
    z-index: 9999;
    right: 0;
    background: #2f348b;

    a {
        position: relative;
        display: block;

        &.ic-msg {
            background: url('../images/material/ic-msg.png') no-repeat center;
            width: 55px;
            height: 54px;
        }

        &.ic-info {
            background: url('../images/material/ic-info.png') no-repeat center;
            width: 55px;
            height: 54px;
        }

        &.ic-news {
            background: url('../images/material/ic-news.png') no-repeat center;
            width: 55px;
            height: 54px;
        }
    }
}

/*homes style*/
.home {

    i {
        &.tittle {
            color: #1d26a6;
            font-size: 18px;
            display: block;
            margin-bottom: 20px;

            &.blue {
                text-align: left;
            }
        }
    }

    &.instgram {
        padding: 110px 0px 0;
        box-sizing: border-box;

        .wrapper {
            &::after {
                display: none;
            }
        }

        &::after {
            display: none;
        }

        .tittle-sec {
            text-align: center;
            margin-bottom: 65px;

            h2 {
                font-weight: 500;
                color: #333;
            }
        }

        .feed {
            display: flex;
            margin: 0 -10px;
            align-items: flex-end;

            a {
                padding: 0 10px;
                flex: 0 0 25%;
                max-width: 25%;
                position: relative;
                box-sizing: border-box;
                display: block;
                max-height: 225px;
                overflow: hidden;

                img {
                    display: block;
                    width: 100%;
                    object-fit: cover;
                    height: 225px;

                }

                &:nth-child(even) {
                    margin-bottom: 40px;
                    align-self: flex-start;
                }

                &::after {
                    content: '';
                    position: absolute;
                    left: 10px;
                    top: 0;
                    bottom: 0;
                    right: 10px;
                    background: linear-gradient(0deg, rgba(21, 27, 101, 0.5), rgba(21, 27, 101, 0.5));
                    transition: .2s all ease-out;
                    opacity: 0;
                }

                &:hover {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.what-other {
        padding: 100px 0px;
        box-sizing: border-box;

        .title-sec {
            text-align: center;

            h2 {
                margin-bottom: 30px;
                font-size: 36px;
                font-weight: 600;
                color: #333333;
            }
        }

        .slider {
            figure {
                margin: 0;
                position: relative;
                overflow: hidden;

                img {
                    width: 100%;
                    transition: .2s all ease-out;
                }

                figcaption {
                    padding-top: 60px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    text-align: center;
                    color: #fff;
                    opacity: 0;
                    transition: .2s all ease-out;

                    h4,
                    p {
                        z-index: 2;
                        position: relative;
                    }

                    h4 {
                        font-weight: 600;
                        margin-bottom: 10px;
                    }

                    p {
                        margin-bottom: 22px;
                        font-size: 16px;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        background: url('../images/material/shd-blue.png')no-repeat top;
                        background-size: cover;
                        z-index: inherit;
                    }
                }
            }

            .list {
                margin: 0 15px;

                &:hover {
                    figure {
                        img {
                            transform: scale(1.05);
                        }

                        figcaption {
                            opacity: 1;
                        }
                    }
                }
            }

            .slick-arrow {
                position: absolute;
                width: 60px;
                height: 44px;
                background-color: #151B65;
                z-index: 2;

                &::before {
                    content: '';
                    background: url('../images/material/ic-arr-right-y.png')no-repeat center;
                    width: 8px;
                    height: 12px;
                    z-index: 2;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                }

                &.slick-prev {
                    right: calc(100% + 10px);
                    left: auto;

                    &::before {
                        transform: rotate(180deg);
                    }
                }

                &.slick-next {
                    left: calc(100% + 10px);
                    right: auto;
                }
            }
        }
    }

    &.visimisi {
        position: relative;

        .bg-visi {
            background: url('../images/content/bg-visimisi.png')no-repeat left;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 775px;
            height: 378px;
            z-index: -1;
        }

        .tittle-sec {
            text-align: center;
            width: 820px;
            margin: 195px auto 95px;
            position: relative;
            height: 175px;

            &:before {
                width: 100px;
                height: 3px;
                background: #1b2287;
                display: block;
                content: '';
                bottom: 0;
                left: 0;
                right: 0;
                position: absolute;
                margin: auto;
            }

            h2 {
                font-size: 44px;
                color: #333;
                text-transform: uppercase;
                font-weight: 600;
            }

            p {
                font-family: $f_f2;
                font-size: 18px;
                line-height: 28px;
                color: #5c616f;
                padding-top: 28px;
            }
        }

        .box-visimisi {
            position: relative;
            min-height: 437px;
            padding-left: 376px;
            padding-top: 42px;
            @include boxSizing(border-box);

            h3 {
                font-size: 35px;
                color: #333333;
                line-height: 42px;
            }

            p {
                font-family: $f_f2;
                font-size: 18px;
                line-height: 28px;
                color: #5c616f;
                margin-bottom: 65px;
            }

            .images-visi {
                background: url('../images/material/visimisi.png')no-repeat center;
                width: 361px;
                height: 457px;
                position: absolute;
                left: 0;
                bottom: 0;
            }

            .visi {
                width: 40%;
                float: left;
                margin-right: 10%;

                &:after {
                    display: block;
                    content: '';
                    clear: both;
                }
            }

            .misi {
                width: 40%;
                float: left;

                a {
                    font-size: 14px;
                    font-weight: 700;
                    color: #be1b19;
                    padding-right: 50px;

                    &.detail {
                        background: url('../images/material/arr-right-red.png')no-repeat right;
                    }
                }

                &:after {
                    display: block;
                    content: '';
                    clear: both;
                }
            }
        }
    }

    &.news-event {
        background: #eaeaea;
        padding-bottom: 123px;

        .tittle-news {
            width: 100%;
            padding-top: 145px;
            height: 80px;
            margin-bottom: 35px;

            .titt {
                float: left;

                &:after {
                    display: block;
                    content: '';
                    clear: both;
                }
            }

            .seeall {
                float: right;

                &:after {
                    display: block;
                    content: '';
                    clear: both;
                }

                a {
                    font-size: 14px;
                    font-weight: 700;
                    color: #be1b19;
                    padding-right: 50px;
                    display: inline-block;
                    padding-top: 50px;
                    position: relative;
                    text-transform: uppercase;

                    &.detail {
                        &:before {
                            background: url('../images/material/arr-right-red.png')no-repeat right;
                            position: absolute;
                            top: 50px;
                            right: 0;
                            bottom: 0;
                            content: '';
                            width: 26px;
                            height: 13px;
                        }
                    }
                }
            }
        }

        .box-list {
            margin: 0 -12.5px 25px;

            .box {
                float: left;
                width: 50%;
                padding: 0 12.5px;
                @include boxSizing(border-box);

                &:after {
                    display: block;
                    content: '';
                    clear: both;
                }

                &.big {
                    width: 50%;
                    float: left;

                    &:after {
                        display: block;
                        content: '';
                        clear: both;
                    }

                    figure {
                        margin: 0;
                        position: relative;
                        display: block;
                        overflow: hidden;

                        img {
                            width: 100%;
                            @include transition(0.2s ease-out all);
                            filter: brightness(50%);
                        }

                        i {
                            &.round {
                                position: absolute;
                                left: 55px;
                                top: 30px;
                                font-size: 14px;
                                color: #666666;
                                font-family: $f_f2;
                                font-weight: 400i;

                                &.wht {
                                    color: #fff;
                                }
                            }
                        }

                        a {

                            &.video {
                                &:after {
                                    content: '';
                                    background: url('../images/material/ic-play.png')no-repeat center;
                                    width: 72px;
                                    height: 72px;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    margin: auto;
                                }
                            }
                        }

                        span {
                            &.ico {
                                position: absolute;
                                top: 30px;
                                right: 55px;
                                background: url('../images/material/ico-news.png')no-repeat center;
                                width: 30px;
                                height: 30px;
                            }

                            &.video {
                                position: absolute;
                                top: 30px;
                                right: 55px;
                                background: url('../images/material/ic-video.png')no-repeat center;
                                width: 28px;
                                height: 21px;
                            }
                        }

                        .caption {
                            position: absolute;
                            bottom: 30px;
                            left: 55px;
                            color: #fff;
                            width: 405px;
                            overflow: hidden;
                            line-height: 30px;

                            h5 {
                                font-size: 22px;
                                padding-top: 20px;
                            }
                        }

                        &:hover {
                            img {
                                @include transform(scale(1.1));
                                filter: brightness(90%);
                            }
                        }
                    }
                }

                .sub-box {

                    background-color: #fff;
                    width: 48.135%;
                    float: left;
                    margin-right: 3.73%;
                    height: 347px;
                    padding: 30px;
                    @include boxSizing(border-box);
                    position: relative;

                    a {
                        color: #333;

                        i {
                            &.round {
                                font-size: 14px;
                                color: #666666;
                                font-family: $f_f2;
                                font-weight: 400;
                                color: #666666;
                            }
                        }

                        h4 {
                            font-size: 30px;
                            line-height: 36px;
                            margin-top: 50px;
                            margin-bottom: 30px;
                            height: 100px;
                            overflow: hidden;
                        }

                        .loc {
                            position: absolute;
                            bottom: 15px;
                            border-top: 1px solid #999;
                            padding-top: 15px;

                            h5 {
                                font-family: $f_f2;
                                color: #999999;
                            }
                        }
                    }

                    &:after {
                        display: block;
                        content: '';
                        clear: both;
                    }

                    &:nth-child(2n) {
                        margin-right: 0;
                    }

                    &:hover {
                        background: #fbb41f;
                        @include transition(0.2s ease-out all);

                        a {
                            .loc {
                                border-color: #fff;

                                h5 {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.virtual {
        position: relative;

        .img-full {
            img {
                width: 100%;
            }
        }

        .caption-virtual {
            position: absolute;
            left: 0;
            top: 30%;
            bottom: 0;
            right: 0;
            margin: 0 auto;
            width: 850px;
            height: 220px;
            text-align: center;

            h1 {
                font-weight: 500;
                font-size: 46px;
                color: #fff;
                margin-top: 30px;
                margin-bottom: 20px;
            }

            p {
                font-size: 18px;
                color: #fff;
                font-family: $f_f2;
            }

            a {
                display: inline-block;
                color: #fff;
                text-decoration: underline;
                font-size: 13.73px;
                text-transform: uppercase;
                margin-top: 48px;
            }

            img {
                text-align: center;
            }
        }
    }

    .tittle {
        text-align: center;

        h1 {
            font-size: 46px;
            font-weight: 500;
            text-transform: uppercase;
            padding: 100px 0 80px;

            &.num {
                padding: 0 0 50px;
            }
        }

    }

    &.family {
        background-color: #e4e4e4;
        padding-bottom: 265px;

        .box-img {

            .list-img {
                float: left;
                position: relative;
                cursor: pointer;
                overflow: hidden;

                &:after {
                    content: '';
                    clear: both;
                    display: block;
                }

                img {
                    @include transition(0.2s all ease-out)
                }

                a {
                    &:before {
                        background: rgba(255, 173, 0, 0.5);
                        content: '';
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        opacity: 0;
                        display: block;
                        z-index: 2;
                        height: 100%;
                        position: absolute;
                    }

                    &:hover {
                        &:before {
                            opacity: 1;
                            @include transition(0.15s linear all);
                        }
                    }
                }

                a {
                    display: block;
                    z-index: 1;
                    position: relative;
                }

                .label {
                    position: absolute;
                    bottom: 25px;
                    left: 25px;

                    h5 {
                        font-size: 24px;
                        color: #f9f9f9;
                        font-weight: 500;
                    }
                }

                &:hover {

                    img {
                        @include transform(scale(1.1));
                    }
                }
            }
        }
    }

    &.family-slider {
        padding: 90px 0;
        box-sizing: border-box;

        .title-sec {
            text-align: center;
            margin-bottom: 50px;

            h2 {
                font-size: 36px;
                font-weight: 600;
                color: #333;
            }
        }

        .slider {
            figure {
                margin: 0;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    background: linear-gradient(0deg, rgba(21, 27, 101, 0.53), rgba(21, 27, 101, 0.53));
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition: .2s all ease-out;
                }

                img {
                    width: 100%;
                }

                figcaption {
                    position: absolute;
                    bottom: 25px;
                    left: 25px;
                    right: 25px;
                    color: #fff;
                    font-size: 16px;
                    font-weight: bold;
                    opacity: 0;
                    z-index: 2;
                    transition: .2s all ease-out;
                }
            }

            .list {
                &:nth-child(odd) {
                    figure {
                        z-index: 2;

                        img {
                            transform: scale(1.05);
                            border: 10px solid #fff;
                            box-sizing: border-box;
                        }

                        &::after {
                            transform: scale(1.05);
                            width: 100%;
                            border: 10px solid #fff;
                            box-sizing: border-box;
                        }
                    }
                }

                &:hover {
                    figure {
                        figcaption {
                            opacity: 1;
                        }

                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }

            .slick-track {
                display: flex;
                align-items: center;

                &::before,
                &::after {
                    display: none;
                }
            }

            .slick-arrow {
                position: absolute;
                width: 60px;
                height: 44px;
                background-color: #151B65;
                z-index: 2;

                &::before {
                    content: '';
                    background: url('../images/material/ic-arr-right-y.png')no-repeat center;
                    width: 8px;
                    height: 12px;
                    z-index: 2;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                }

                &.slick-prev {
                    right: calc(100% + 10px);
                    left: auto;

                    &::before {
                        transform: rotate(180deg);
                    }
                }

                &.slick-next {
                    left: calc(100% + 10px);
                    right: auto;
                }
            }
        }
    }

    &.number {
        .box-top {
            // margin-top: -200px;

            .box-info {
                margin-bottom: 120px;

                &:after {
                    display: block;
                    content: '';
                    clear: both;
                }

                .list-info {
                    width: 339px;
                    height: 180px;
                    background: #192183;
                    float: left;
                    border-right: 1px solid #3a4097;

                    .isi {
                        padding: 35px 40px 30px;
                        position: relative;
                        width: 100%;
                        height: 100%;
                        @include boxSizing(border-box);

                        i {
                            &.info {
                                position: absolute;
                                bottom: 30px;
                                left: 40px;
                                color: #fff;
                                font-size: 16px;
                                font-weight: 400i;
                            }
                        }

                        h2 {
                            position: absolute;
                            bottom: 25px;
                            right: 10px;
                            color: #fff;
                            font-size: 40px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        .box-bot {
            padding-bottom: 150px;

            .tittle-info {
                padding-bottom: 47px;

                h1 {
                    font-size: 36px;
                    font-weight: 400;
                    text-transform: uppercase;
                }
            }
        }
    }
}

span {
    &.tgl {
        font-size: 14px;
        margin-bottom: 25px;
        color: #1b2287;
        font-weight: 500;

        &.wht {
            color: #fff;
        }
    }
}

.map {
    position: relative;

    #idMap {
        height: 380px;
        width: 100%;
        position: relative;
        z-index: 1;
    }

    .txt-map {
        position: absolute;
        top: 70px;
        right: 210px;
        bottom: 0;
        width: 380px;
        height: 240px;
        background: #fff;
        z-index: 2;
        padding: 40px 60px;
        @include boxSizing(border-box);
        @include boxShadow(0px 0px 10px 2px rgba(0, 0, 0, 0.5));

        h5 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 20px;
        }

        p {
            font-family: $f_f2;
            font-size: 16px;
            line-height: 22px;
        }

        a {
            &.get-drt {
                font-size: 14px;
                color: #be1b19;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }

    &.contactus {
        padding: 75px 97px 0;
        @include boxSizing(border-box);

        #idMap {
            height: 590px;
        }

        .txt-map {
            top: 190px;
            right: 150px;
        }
    }

}

/*-----------------------------MIDDLE-----------------------------------------------*/

.middle-content {
    background: #f5f5f5;
    position: relative;
    z-index: 2;
}

.banner-std {
    z-index: 1;

    img {
        width: 100%;
    }
}

.sub-content {
    padding-left: 115px;
    position: relative;

    &.havemrg {
        margin-top: 50px;

        p {
            width: 350px;
            margin-bottom: 45px;
        }
    }

    &.marginbot {
        margin-bottom: 80px;
    }

    &.rightpadding {
        padding-right: 75px;
    }

    .left-absolute {
        position: absolute;
        right: 855px;
        top: 0;
    }

    .quotes {
        position: relative;
        margin-bottom: 60px;

        &:before {
            content: '';
            background: url('../images/material/quote.png')no-repeat center;
            width: 48px;
            height: 38px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &.full {
        padding-left: 0;
    }

    .sign {
        margin-bottom: 150px;
        margin-top: 135px;
        position: relative;

        &.bfr {
            margin-bottom: 0;
            margin-top: 0;
            font-style: italic;

            &:before {
                content: '';
                display: block;
                width: 20px;
                height: 3px;
                background: #1b2287;
                position: absolute;
                top: 15px;
                left: 0;
            }
        }

        h4 {
            font-size: 20px;
            color: #1b2287;
            font-weight: 700;
            margin-bottom: 0px;
        }
    }

    .wrap-anthem {
        &:after {
            content: '';
            clear: both;
            display: block;
        }

        .eng {
            float: left;
            width: 40%;
        }

        .ind {
            float: right;
            width: 40%;
        }
    }
}

.box-circ {
    margin-bottom: 115px;

    &:after {
        content: "";
        clear: both;
        display: block;
    }

    .list-circ {
        float: left;
        width: 16.66666666666666%;

        &:after {
            display: block;
            clear: both;
            content: '';
        }

        .circle {
            background: #ffad00;
            text-align: center;
            width: 145px;
            height: 145px;
            @include borderRadius(100%);
            padding: 0 10px;
            @include boxSizing(border-box);
            margin-right: 60px;
            position: relative;

            h6 {
                color: #1b2287;
                font-size: 16px;
                font-family: $f_f1;
                font-weight: 500;
                line-height: 145px;
                word-break: break-all;
                overflow: hidden;
            }

            &:before {
                content: '';
                width: 100%;
                height: 3px;
                background: #b7b7b7;
                position: absolute;
                z-index: -1;
                top: 50%;
                margin-top: -1.5px;
                left: 100%;
            }

            &:after {
                content: '';
                position: absolute;
                z-index: 1;
                top: -10px;
                left: -10px;
                right: -10px;
                bottom: -10px;
                border: 10px solid #fff;
                @include borderRadius(100%);
            }
        }

        &:last-child {
            .circle {
                &:before {
                    display: none;
                }
            }
        }
    }
}

.content-std {
    background: #fff;
    z-index: 5;
    position: relative;

    h1 {
        position: relative;
        font-size: 42px;
        font-weight: 700;
        color: #1b2287;
        padding-bottom: 40px;
        margin-bottom: 45px;
        max-width: 680px;

        &:after {
            content: '';
            width: 100px;
            height: 3px;
            background: #1b2287;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &.noborder {
            margin-bottom: 20px;
            padding-bottom: 0;

            &:after {
                display: none;
            }
        }
    }

    h2 {
        font-size: 32px;
        font-family: $f_f2;
        font-weight: 400;
        font-style: italic;
        color: #999999;
        margin-bottom: 45px;

        &.blue {
            font-size: 30px;
        }

        &.std {
            font-style: normal;
            line-height: 42px;
            margin-bottom: 0;
        }

        &.bld {
            font-weight: 700;
        }
    }

    h3 {
        font-size: 26px;
        font-family: $f_f2;
        line-height: 36px;
        color: #666666;

        &.bl {
            color: #333;
        }

        &.bl-f1 {
            font-family: $f_f1;
            color: #333;
            font-weight: 500;
        }

        &.itl {
            font-style: italic;
            border-top: 1px solid #ccc;
            padding-top: 48px;
        }
    }

    h4 {
        font-size: 18px;
        font-weight: 700;
        color: #1b2287;
        margin-bottom: 15px;

        &.bld {
            font-family: $f_f2;
            font-size: 24px;
            font-weight: 700;
            color: #272727;
        }
    }

    h5 {
        font-size: 18px;
        font-weight: 700;

        &.reg {
            font-weight: 400;
        }

        &.reg2 {
            font-weight: 400;
            font-family: $f_f2;
        }

        &.content {
            line-height: 30px;
        }
    }

    h6 {
        font-size: 16px;

        &.bld {
            font-weight: 700;
            color: #333;
            font-family: $f_f2;
        }

        &.std {
            color: #666666;
            font-family: $f_f2;
            margin-bottom: 10px;
        }

        &.itl {
            font-style: italic;
            color: #1b2287;
            margin-bottom: 40px;
        }
    }

    p {
        font-family: $f_f2;
        font-size: 18px;
        line-height: 30px;
        color: #333333;
        position: relative;

        &.gry {
            color: #999999;
            font-size: 20px;
        }

        &.pad {
            padding-left: 40px;
            margin-bottom: 0;
        }

        &.bld {
            font-weight: 700;
            margin-bottom: 5px;
        }

        &.list {
            padding-left: 45px;

            &:before {
                content: '';
                width: 20px;
                height: 3px;
                position: absolute;
                left: 5px;
                top: 15px;
                background: #1b2287;
            }
        }
    }

    .wrap-table {
        position: relative;
        overflow-x: auto;

        thead {
            background: #1b2287;
            color: #fff;
        }

        table {
            margin-bottom: 80px;

            tr {

                th,
                td {
                    border: 1px solid #d6d6d6;
                    padding: 20px 15px;
                    font-size: 16px;
                    font-family: $f_f2;
                    @include boxSizing(border-box);
                    text-align: center;
                    width: 150px;

                    /*   &:nth-child(2){ width: 150px; }
                      &:nth-child(3){ width: 150px; }*/
                    &.sub {
                        background: #1b2287;
                        color: #fff;
                    }
                }
            }
        }
    }

    span {
        &.tittl-tgl {
            font-size: 16px;
            color: #666666;
            font-family: $f_f2;
            margin-bottom: 30px;
            display: block;
        }

        &.line {
            width: 100px;
            height: 3px;
            background: #1b2287;
            display: block;
            margin-bottom: 70px;

            &.ccc {
                width: 100%;
                height: 1px;
                background: #ccc;
            }
        }
    }


    .wrap-btnloadmore {
        margin: 40px 0 0;
        text-align: center;
        padding-bottom: 300px;
    }

    .flow {
        margin-bottom: 70px;
    }

    .inner-content {
        padding: 70px 210px 125px;
        position: relative;
        @include boxSizing(border-box);

        &.havebg {
            background: url('../images/material/bg-content.png')no-repeat 130% 140px;
        }

        &.nopaddingbottom {
            padding-bottom: 0;
        }

        &.filt {
            .wrap-select {
                position: relative;
                display: inline-block;
                float: right;

                &:after {
                    display: block;
                    clear: both;
                    content: '';
                }

                span {
                    position: absolute;
                    background: url('../images/material/ic-arr-bl.png')no-repeat left;
                    width: 17px;
                    height: 10px;
                    right: 15px;
                    top: 20px;
                    z-index: 1;
                }

                select {
                    width: 239px;
                    height: 46px;
                    position: relative;
                    z-index: 3;
                    background: none;
                    cursor: pointer;
                }
            }
        }

        ul {

            margin-bottom: 50px;

            &.m {
                margin-top: 40px;
            }

            li {
                background: url('../images/material/ic-li.png')no-repeat left 10px;
                padding-left: 35px;
                margin-left: 25px;
                margin-bottom: 20px;
                font-size: 18px;
                font-family: $f_f2;
                line-height: 30px;
                position: relative;

                &.sub-ctn {
                    margin-left: 0;
                    margin-bottom: 5px;
                }

                &:after {
                    display: block;
                    clear: both;
                    content: '';
                }

                &.num {
                    list-style-type: decimal;
                    background: none;
                    padding-left: 20px;
                    margin-bottom: 0;
                }

            }

            &.strip {
                li {
                    background: none;

                    &:before {
                        content: '';
                        width: 20px;
                        height: 3px;
                        position: absolute;
                        left: 5px;
                        top: 15px;
                        background: #1b2287;
                    }
                }
            }

            &.dot {
                li {
                    &:before {
                        content: "";
                        position: absolute;
                        top: 8px;
                        left: 7px;
                        width: 6px;
                        height: 5px;
                        background: #272727;
                        border-radius: 100%;
                        -webkit-border-radius: 100%;
                    }
                }
            }
        }

        ol {
            margin-bottom: 25px;

            &.nomarg {
                margin-bottom: 0;
            }

            li {
                position: relative;
                padding-left: 20px;
                margin-bottom: 10px;
                margin-left: 30px;
                font-size: 18px;
                color: #333;
                font-family: $f_f2;
                background: none;
                list-style: decimal;

                &.bullet-sub {
                    margin-left: 0;
                    padding-left: 15px;
                    margin-bottom: 0;

                    &:before {
                        top: 14px;
                        left: 0;
                    }
                }
            }

            &.roman {
                li {
                    list-style-type: lower-roman;
                    background: none;
                    padding-left: 15px;
                    margin-bottom: 10px;
                }
            }
        }

        .li-font {
            font-size: 18px;
            font-weight: 400;
            padding-left: 25px;
            float: left;

            &.prc {
                &:before {
                    content: ':';
                    display: inline-block;
                }
            }
        }

        .ico-left-s {
            background: url('../images/material/logo-s.png')no-repeat center;
            width: 57px;
            height: 56px;
            position: absolute;
            left: 225px;
            top: 345px;
        }

        .ico-left-m {
            background: url('../images/material/ico-logo-content.png')no-repeat center;
            width: 151px;
            height: 152px;
            position: absolute;
            bottom: 500px;
            left: 90px;
        }

        .wrap-list {
            margin-top: 60px;

            .list-organi {
                border-top: 1px solid #d8d8d8;

                &:last-child {
                    border-bottom: 1px solid #d8d8d8;
                }

                &:after {
                    display: block;
                    clear: both;
                    content: '';
                }

                .org {
                    width: 100%;

                    .org-left {
                        float: left;
                        text-align: right;
                        width: 49%;
                        padding-top: 25px;
                        padding-bottom: 40px;
                    }

                    .org-right {
                        float: right;
                        text-align: left;
                        width: 49%;
                        padding-top: 25px;
                        padding-bottom: 15px;
                    }
                }
            }
        }

        .infor-collage {
            background: #eeeeee;
            padding: 25px 0;
            text-align: center;
            line-height: 24px;
            margin-top: 30px;
            margin-bottom: 40px;

            &.requir {
                padding: 45px 115px;
            }

            .bl {}

            a {
                display: inline-block;

                &.click-here {
                    font-size: 16px;
                    color: #666666;
                    text-decoration: underline;
                }
            }

        }

        .contact-infor {
            width: 100%;

            &:after {
                display: block;
                clear: both;
                content: '';
            }

            .list-contact {
                width: 48%;
                float: left;
                border: 1px solid #d8d8d8;
                margin-right: 4%;
                @include boxSizing(border-box);
                padding: 25px 30px;

                &:nth-child(2n) {
                    margin-right: 0;
                }

                .bot-con {
                    border-top: 1px solid #d8d8d8;
                    padding-top: 10px;
                    margin-top: 60px;

                    h6 {
                        font-size: 16px;
                        font-family: $f_f1;
                        color: #999999;

                        a {
                            color: #999999;
                        }
                    }
                }
            }
        }

        .box-circ {
            margin-right: -115px;
        }

        .logo-partn {
            margin-top: 5px;
            margin-bottom: 45px;

            &:after {
                clear: both;
                content: '';
                display: block;
            }

            img {
                float: left;
                margin-right: 70px;
            }
        }

        .content-src {
            .search-text {
                margin-bottom: 40px;

                input[type="text"] {
                    width: 100%;
                    font-size: 40px;
                    border: none;
                    height: auto;
                    border-bottom: 1px solid #ccc;
                    background: url('../images/material/ico-search-blue.png')no-repeat right 30px;
                    padding-right: 20px;
                    @include boxSizing(border-box);
                    margin-bottom: 15px;
                }

                small {
                    font-size: 16px;
                    text-align: left;
                    color: #999;
                }
            }

            .box-search {
                position: relative;

                .list-search {
                    margin: 0 0 30px 0;
                    padding-bottom: 30px;
                    border-bottom: 1px solid #eaeaea;

                    a {
                        display: block;

                        h3 {
                            margin-bottom: 10px;
                        }
                    }
                }
            }

        }

        .filosofi {
            margin-bottom: 100px;

            &:after {
                content: '';
                display: block;
                clear: both;
            }

            .logo-big {
                float: left;
                width: 44%;
                margin-top: 20px;

                .wrap-span {
                    float: left;
                    position: relative;

                    img {
                        vertical-align: top;
                    }

                    >span {

                        background: rgba(30, 33, 136, 0.8);
                        @include borderRadius(100%);
                        position: absolute;
                        width: 33px;
                        height: 33px;
                        cursor: pointer;
                        @include transition(0.3s ease-out all);

                        .small {
                            width: 11px;
                            height: 11px;
                            background: rgba(255, 255, 255, 1);
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            @include borderRadius(100%);
                            margin: auto;
                            @include transition(0.3s ease-out all);
                        }

                        .medium {
                            width: 30px;
                            height: 30px;
                            @include borderRadius(100%);
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            margin: auto;
                        }

                        &.top {
                            right: 40px;
                            top: 25px;
                        }

                        &.right {
                            right: 15px;
                            bottom: 30px;
                        }

                        &.left {
                            left: 35px;
                            bottom: 100px;
                        }

                        &.active {
                            position: absolute;
                            background: rgba(30, 33, 136, 0.8);
                            @include borderRadius(100%);
                            width: 50px;
                            height: 50px;
                            cursor: pointer;

                            .small {
                                width: 15px;
                                height: 15px;
                                @include borderRadius(100%);
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                background: rgba(255, 187, 43, 1);
                                margin: auto;
                            }

                            .medium {
                                background: rgba(255, 255, 255, 0.8);
                                @include borderRadius(100%);
                                position: absolute;

                            }
                        }

                    }
                }
            }

            .accordion {
                float: right;
                width: 55%;
            }
        }

    }

    .box-wrap-news {
        padding: 0 100px;
        @include boxSizing(border-box);

        .list-news {
            width: 100%;
            margin-bottom: 40px;
            border-bottom: 1px solid #ccc;
            padding-bottom: 40px;

            &:after {
                clear: both;
                content: '';
                display: block;
            }

            .foto {
                float: left;
                width: 48%;
                margin-right: 40px;
            }

            .text-news {
                float: left;
                width: 48%;

                h3 {
                    font-weight: 600;
                    margin: 20px 0 30px;
                }

                p {
                    margin-bottom: 20px;
                }

                h6 {
                    font-weight: 600;
                    color: #5e5e5e;
                }
            }
        }
    }

    .wrap-newsdetail {
        padding: 60px 67px 100px;
        position: relative;

        &:before {
            content: '';
            height: 1px;
            background: #ccc;
            right: 67px;
            left: 67px;
            position: absolute;
            top: 0;
        }

        .tittle-newsdetail {
            h2 {
                font-size: 20px;
                color: #002d72;
                font-style: normal;
                font-family: $f_f1;
                font-weight: 600;
                padding: 0 15px;
                margin-bottom: 35px;
            }
        }

        .box-newsdetail {
            &:after {
                clear: both;
                content: '';
                display: block;
            }

            .list-newsdetail {
                float: left;
                width: 33.33333%;
                padding: 0 15px;
                @include boxSizing(border-box);

                .foto {
                    img {
                        width: 100%;
                    }
                }

                .text {
                    padding: 10px 10px;

                    h5 {
                        margin-top: 15px;
                        color: #333;
                    }
                }
            }
        }
    }

    .form {
        hr {
            width: 540px;
            margin: 35px 0;
            border: none;
            height: 1px;
            background: #ccc;
        }

        .row {
            margin-bottom: 15px;

            &:after {
                content: '';
                display: block;
                clear: both;
            }

            label {
                position: relative;
                padding-right: 10px;
                font-size: 18px;
                color: #000;
                font-family: $f_f2;
                width: 150px;
                float: left;
                display: block;
                line-height: 35px;

                &:before {
                    content: ':';
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }

            &.line {
                border-bottom: 1px solid #ccc;
            }

            .field {
                display: block;
                margin-left: 180px;
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }

                input[type='text'],
                input[type='email'],
                input[type='file'] {
                    width: 360px;
                    height: 35px;
                    border: 2px solid #e1e1e1;
                    @include boxSizing(border-box);

                    &.num {
                        padding-left: 50px;
                    }
                }

                select {
                    width: 360px;
                    height: 35px;
                    position: relative;
                    border: 2px solid #e1e1e1;
                    background: url('../images/material/ic-arr-dwn-b.png') no-repeat 95% center;

                }

                .opt {
                    font-size: 18px;
                    color: #000;
                    font-family: $f_f2;
                    display: inline-block;
                    padding-left: 25px;
                    position: relative;
                    line-height: 35px;
                    margin-right: 20px;

                    &:last-child {
                        margin-right: 0;
                    }

                    input[type='radio'] {
                        background: url('../images/material/ic-radio.png') no-repeat 0 center;
                        margin: auto;
                        /* width: 18px; */
                        width: 100%;
                        height: 18px;
                        top: 5px;
                        position: absolute;
                        left: 0;
                        top: 8px;
                        z-index: 2;

                        &:checked {
                            background: url('../images/material/ic-radio-active.png') no-repeat 0 center;
                        }
                    }
                }

                .input_file {
                    width: 360px;
                    height: 35px;
                    border: 2px solid #e1e1e1;
                    padding: 10px;
                    @include boxSizing(border-box);
                    color: #c2c2c2;
                }

                .brows-file {
                    font-size: 12px;
                    color: #086fc7;
                    text-decoration: underline;
                    margin-top: 10px;
                    position: relative;
                    padding-left: 30px;
                    display: block;

                    &:before {
                        content: '';
                        background: url('../images/material/ic-folder.png')no-repeat center;
                        width: 16px;
                        height: 12px;
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }

                .phone-num {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    padding-right: 12px;
                    border-right: 1px solid #000;
                    color: #000;
                }

                &.add {
                    input[type='text'] {
                        margin-bottom: 10px;
                    }

                    a {
                        &.addfield {
                            display: block;
                            font-family: $f_f2;
                            font-size: 12px;
                            text-decoration: underline;
                            margin-bottom: 30px;
                        }
                    }
                }
            }

            input[type='submit'] {
                width: 211px;
                height: 50px;
                background: #1b2287;
                margin-top: 70px;
                font-size: 18px;
                color: #fff;
                cursor: pointer;
            }
        }

        .form-chkbox {
            .chkbox {
                font-size: 18px;
                font-family: $f_f2;
                color: #000;
                padding-left: 35px;
                position: relative;
                margin-bottom: 15px;

                input[type='checkbox'] {
                    background: url('../images/material/ic-chkbox.png') no-repeat 0 0;
                    /*width: 18px;*/
                    width: 100%;
                    height: 20px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 2;

                    &:checked {
                        background: url('../images/material/ic-chkbox-active.png') no-repeat 8px center;
                        width: 8px;
                        height: 9px;
                    }
                }
            }
        }
    }

    .wrap-box-admission {
        &:after {
            content: '';
            display: block;
            clear: both;
        }

        .list-box {
            float: left;
            margin-right: 6%;
            width: 47%;
            margin-bottom: 45px;

            &:nth-child(2n) {
                margin-right: 0;
            }

            @include boxShadow(0px 0px 4px 2px rgba(0, 0, 0, 0.2));

            .box {
                padding: 35px 35px;
                @include boxSizing(border-box);

                h5 {
                    font-size: 18px;
                    color: #999999;
                    font-weight: 400;
                    font-family: $f_f1;
                    margin-bottom: 10px;
                }

                h4 {
                    font-size: 24px;
                    color: #333333;
                    font-weight: 700;
                    font-family: $f_f1;
                    margin-bottom: 0;
                    line-height: 20px;
                }

                .wrap-txt {
                    height: 80px;
                    overflow: hidden;
                    margin-bottom: 15px;
                }
            }

            .grid-email {
                background: #ce3043;
                height: 60px;
                position: relative;

                &:before {
                    width: 1px;
                    height: 100%;
                    background: #f08592;
                    opacity: 0.3;
                    position: absolute;
                    content: '';
                    left: 60px;
                    top: 0;
                }

                &:hover {
                    background: #df354c;
                }

                img {
                    position: absolute;
                    left: 18px;
                    top: 20px;

                }

                span {
                    font-size: 14px;
                    color: #fff;
                    position: absolute;
                    left: 80px;
                    top: 20px;
                    padding-right: 15px;

                }

                .arr {
                    background: url('../images/material/ic-arr-wht-d.png')no-repeat center;
                    width: 14px;
                    height: 9px;
                    position: absolute;
                    right: 10px;
                    top: -5px;
                    display: inline-block;
                    left: auto;
                    bottom: 0;
                    margin: auto;

                    &.less {
                        background: url('../images/material/ic-arr-wht-u.png')no-repeat center;
                        width: 14px;
                        height: 9px;
                        position: absolute;
                        right: 10px;
                        top: -5px;
                        display: inline-block;
                        left: auto;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }

        }
    }

    .list-issue {
        margin: 0 96px;

        .box-issue {
            float: left;
            position: relative;
            padding: 0;
            border: 2px solid #1b2287;
            margin: 0 75px 75px 0;
            width: 320px;
            height: 240px;

            /*&:before{content:''; display: block; width: 100%; position: absolute; top: 30px; left: -30px; }   */
            &:nth-child(3n+3) {
                margin-right: 0px;
            }

            .issue {
                width: 347px;
                height: 246px;
                background: #f0f0f0;
                position: absolute;
                top: 30px;
                left: 30px;
                height: 100%;
                width: 100%;
                padding: 30px;
                @include boxSizing(border-box);

                h3 {
                    font-size: 26px;
                    font-weight: 700;
                    position: relative;
                    padding-bottom: 50px;
                    height: 30px;
                    overflow: hidden;
                }

                h6 {
                    font-size: 16px;
                    font-weight: 700;
                    color: #5e5e5e;
                    padding-top: 25px;
                    position: relative;

                    &:after {
                        content: '';
                        width: 40px;
                        height: 2px;
                        background: #1b2287;
                        position: absolute;
                        left: 0;
                        top: 0px;
                    }

                }

                .pdf {
                    background: url('../images/material/pdf.png')no-repeat center;
                    position: absolute;
                    bottom: 25px;
                    left: 30px;
                    width: 20px;
                    height: 23px;

                    span {
                        &.size {
                            font-size: 12px;
                            color: #5e5e5e;
                            padding-left: 30px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }

    .grade {
        position: relative;
        border-top: 1px solid #ccc;
        padding-top: 35px;
    }

    .paragraph {
        margin-bottom: 40px;

        h5 {
            font-family: $f_f2;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        &:last-child {
            margin-bottom: 30px;
        }
    }

    .bot-img {
        width: 100%;
        margin-bottom: 80px;
        padding: 0 94px;
        @include boxSizing(border-box);

        &:after {
            content: '';
            display: block;
            clear: both;
        }

        .lis-bot-img {
            float: left;
            width: 20%;
            padding: 0 4px;
            @include boxSizing(border-box);

            &:hover {
                a {
                    h5 {
                        color: #1b2287;
                    }
                }

                .box-img {
                    img {
                        @include transform(scale(1.1));
                    }
                }
            }

            .box-img {
                border: 1px solid #dfdfdf;
                display: block;
                height: 100%;

                img {
                    padding: 25px 20px;
                    @include boxSizing(border-box);
                    @include transition(0.2s ease-out all);
                }
            }

            a {
                h5 {
                    font-size: 18px;
                    color: #272727;
                    text-align: center;
                    margin-top: 28px;
                    font-family: $f_f2;
                }
            }
        }
    }

    .wrap-box-testimoni {
        &:after {
            display: block;
            content: '';
            clear: both;
        }

        .list-testimoni {
            float: left;
            width: 47%;
            margin-right: 6%;
            margin-bottom: 45px;
            @include boxShadow(0px 0px 15px 0px rgba(204, 204, 204, 0.9));

            &:nth-child(2n) {
                margin-right: 0;
            }

            .box-testi {
                padding: 30px;
                @include boxSizing(border-box);

                .text {
                    position: relative;

                    h4 {
                        font-size: 24px;
                        color: #333;
                        font-weight: 700;
                        margin-bottom: 30px;
                    }

                    .wrap-p {
                        position: relative;
                        height: 180px;
                        overflow: hidden;
                        margin-bottom: 10px;

                        &:before {
                            content: '';
                            background: url('../images/material/quote.png') no-repeat center;
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 48px;
                            height: 38px;
                        }

                        p {
                            font-size: 16px;
                            color: #000;
                            line-height: 28px;
                        }
                    }
                }

                &.show {
                    height: auto;
                }

            }

            .bot-testi {

                background-color: #ce3043;
                margin: 0 -30px -30px;
                position: relative;
                padding: 10px 35px;

                &:hover {
                    background: #df354c;
                }

                .bld {
                    font-size: 14px;
                    font-weight: 700;
                    color: #fff;
                    line-height: 18px;
                    display: inline-block;
                    width: 180px;
                    height: auto;
                    overflow: hidden;
                    margin-bottom: 10px;
                    vertical-align: middle;
                }

                .reg {
                    display: block;
                    color: #fff;
                    font-style: italic;
                }

                .read {
                    padding-bottom: 0px;
                    display: inline-block;
                    font-size: 16px;
                    font-family: $f_f2;
                    position: absolute;
                    right: 30px;
                    top: 40%;
                    color: #fff;
                    padding-right: 10px;
                    padding-right: 20px;

                    &:after {
                        background: url('../images/material/ic-arr-wht-d.png')no-repeat center;
                        width: 14px;
                        height: 9px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        content: '';
                        @include transform(rotate(270deg))
                    }

                    &.less {
                        &:after {
                            background: url('../images/material/ic-arr-wht-u.png')no-repeat center;
                            width: 14px;
                            height: 9px;
                            position: absolute;
                            right: -10px;
                            top: 5px;
                        }
                    }
                }

                &.orange {
                    background: #efab1d;

                    &:hover {
                        background: #fbb41f;
                    }
                }
            }
        }
    }
}

.top-menu {
    height: 50px;
    margin-top: -53px;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
    z-index: 100;

    .left {
        &.breadcrumb {
            ul {
                li {
                    display: inline-block;
                    vertical-align: top;
                    height: 50px;
                    line-height: 50px;
                    margin-right: 30px;
                    position: relative;
                    font-size: 12px;
                    font-family: $f_f2;
                    color: #666;
                    font-weight: 700;

                    &:before {
                        content: '';
                        width: 4px;
                        height: 1px;
                        background: #b6986a;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: -20px;
                        margin: auto;

                    }

                    &:first-child:before {
                        display: none;
                    }

                    &:after {
                        display: block;
                        content: '';
                        clear: both;
                    }

                    a {
                        display: block;
                        font-weight: 400;

                        &.home {
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
    }

    .right {
        &.submenu {
            position: relative;

            span {
                line-height: 50px;
                display: inline-block;
                margin-right: 20px;
                font-size: 14px;
                text-transform: uppercase;
                color: #9a9a9a;
            }

            .ico {
                float: right;
                cursor: pointer;

                &:before {
                    background: #1b2287 url('../images/material/ic-arr-dwn-y.png')no-repeat center;
                    width: 50px;
                    height: 50px;
                    content: '';
                    display: block;
                }
            }
        }
    }
}

.box-drop-dots-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background: #161b66;
    width: 300px;
    z-index: 100;
    display: none;

    a {
        color: #fff;
    }

    ul {
        padding-left: 20px;

        li {
            a {
                padding: 20px 12px;
                font-size: 16px;
                line-height: 20px;
                border-bottom: 1px solid #262c85;
                display: block;
                position: relative;
                display: block;

                &:after {
                    content: '';
                    background: #ffbb2b;
                    width: 0px;
                    position: absolute;
                    left: -20px;
                    top: 0;
                    bottom: 0;
                    @include transition(0.2s);
                }

                &:hover {
                    &:after {
                        width: 4px;
                    }
                }
            }

            >ul {
                display: none;

                li {
                    a {
                        &:after {
                            left: -40px;
                        }

                        &:hover {
                            &:after {
                                width: 4px;
                            }
                        }
                    }

                    >ul {
                        li {
                            a {
                                &:after {
                                    left: -60px;
                                }
                            }
                        }
                    }
                }
            }

            &.selected {
                >a {
                    &:after {
                        width: 4px;
                    }
                }
            }

            &.drop {
                >a {
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 25px;
                        right: 25px;
                        background: url('../images/material/ic-arr-dwn-y.png')no-repeat center;
                        width: 14px;
                        height: 9px;
                    }
                }
            }
        }
    }
}

.slide-overview {
    /*margin-left: -115px;*/
    margin-bottom: 50px;
    position: relative;

    .slider-text {
        position: absolute;
        background: #FFF;
        width: 320px;
        height: 350px;
        top: 0px;
        bottom: 0;
        margin: auto;
        right: -140px;
        @include boxShadow(0px 1px 5px 0px rgba(0, 0, 0, 0.5));

        .text {
            padding: 40px 30px;
            @include boxSizing(border-box);

            h6 {
                color: #999999;
                font-family: $f_f2;
                font-size: 16px;
                margin-bottom: 35px;
            }

            h3 {
                font-size: 28px;
                color: #1b2287;
                line-height: 36px;
            }
        }

        .slick-arrow {
            background: #1b2287;
            width: 60px;
            height: 50px;
            padding: 0;
            position: absolute;
            bottom: -25px;
            right: 0;
            top: auto;
            left: auto;

            &.slick-prev {
                right: 60px;
                left: auto;

                &:before {
                    background: url('../images/material/ic-arr-left-y.png')no-repeat center;
                    width: 8px;
                    height: 12px;
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                }

                &:after {
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 30px;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    background: #4c52ad;
                }
            }

            &.slick-next {
                &:before {
                    background: url('../images/material/ic-arr-right-y.png')no-repeat center;
                    width: 8px;
                    height: 12px;
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                }
            }

        }
    }

    .slider-img {
        .images {
            position: relative;
        }

        .slick-arrow {
            z-index: 99;
            background: #1b2287;
            width: 60px;
            height: 50px;

            &.slick-next {
                position: absolute;
                right: 0;
                top: 50%;

                &:before {
                    background: url('../images/material/ic-arr-right-y.png')no-repeat center;
                    width: 8px;
                    height: 12px;
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                }
            }

            &.slick-prev {
                position: absolute;
                left: 0;
                top: 50%;

                &:before {
                    background: url('../images/material/ic-arr-left-y.png')no-repeat center;
                    width: 8px;
                    height: 12px;
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                }
            }
        }
    }
}

.wrappacc {
    &.hvmargin {
        margin-top: 20px;
    }
}

.list-acc {
    .grid-acc {
        width: auto;
        background: #efab1d;
        margin-bottom: 25px;
        padding: 22px 28px;
        position: relative;
        cursor: pointer;

        &:before {
            content: '';
            background: url('../images/material/ic-arr-bl.png')no-repeat left;
            width: 17px;
            height: 10px;
            display: block;
            position: absolute;
            right: 20px;
            top: 30px;
            bottom: 0;
        }

        .tittle-acc {
            font-weight: 700;
            font-size: 24px;
        }

        &.down {
            &:before {
                background: url('../images/material/ic-arr-bl.png')no-repeat right;
            }
        }

        &.issue {
            padding: 15px 15px;

            &:before {
                top: 25px;
            }

            &.down {
                &:before {
                    top: 20px;
                }
            }
        }
    }

    .content-acc {
        display: none;

        &:after {
            display: block;
            clear: both;
            content: '';
        }

        .isi-txt {
            padding: 0 25px;

        }

        ul {
            &.academic-acc {
                li {
                    margin: 0;
                    padding: 15px 25px;
                    background: none;
                    font-size: 18px;

                    &:nth-child(odd) {
                        background: #e7e9fa;
                    }
                }
            }

            li {
                h5 {
                    float: left;
                    width: 270px;
                }
            }
        }

        span {
            float: left;
            width: 150px;
        }

        .pdf {
            display: inline-block;
            position: relative;

            span {
                &.size {
                    font-size: 12px;
                    color: #5e5e5e;
                    line-height: 24px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: auto;
                    text-align: right;
                    margin-right: 30px;
                }
            }

            p {
                position: relative;
                padding-right: 100px;
                margin-bottom: 0;

                &:before {
                    content: '';
                    position: absolute;
                    background: url('../images/material/pdf.png')no-repeat center;
                    width: 20px;
                    height: 23px;
                    right: 5px;
                    top: 0;
                }

                &:after {
                    content: '';
                    width: 1px;
                    background: #ccc;
                    height: 100%;
                    position: absolute;
                    right: 45%;
                    bottom: 0;
                }
            }
        }

        .left {
            width: 60%;
            margin-left: 20px;
        }

        .bar {
            &:after {
                content: '';
                clear: both;
                display: block;
            }
        }
    }

    &.anthem {
        .grid-acc {
            background: transparent;
            border-bottom: 1px solid #ccc;

            &:before {
                content: '';
                background: url('../images/material/arr-acc-gry.png')no-repeat center;
                width: 14px;
                height: 9px;
                position: absolute;
                right: 15px;
                top: 25px;
            }

            .tittle-acc {}

            &.down {
                color: #1b2287;

                &:before {
                    background: url('../images/material/arr-acc-blue.png')no-repeat center;
                }
            }
        }

        .content-acc {
            position: relative;

            p {
                padding-left: 27px;
            }

            &:before {
                content: '';
                width: 5px;
                height: 100%;
                background: #ffad00;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}

.box-acc {
    margin-top: 65px;
}

.wrap-acc {
    .list-acc {
        .grid-acc {
            background: #1b2287;
            color: #fff;
            margin-bottom: 0;
            padding: 15px 25px;
            position: relative;

            .tittle-acc {
                font-weight: 700;
                font-size: 18px;
                font-family: $f_f2;
            }

            &:before {
                background: url('../images/material/ic-arr-wht.png')no-repeat right;
                position: absolute;
                top: 20px;
                right: 15px;
                width: 18px;
                height: 10px;
            }

            &.down {
                &:before {
                    background: url('../images/material/ic-arr-wht.png')no-repeat left;
                    position: absolute;
                    top: 20px;
                    right: 15px;
                    width: 18px;
                    height: 10px;
                }
            }

        }
    }
}


.box-calendar {
    position: relative;

    .box-event-detail {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: #ffffff;
        @include boxSizing(border-box);
        padding: 17px 40px 13px;
        z-index: 1;
        border: 2px solid #9e0b0f;
        display: none;
    }

    .box-list-event {
        .list-event {
            width: 195px;
            margin-right: 25px;
            float: left;
            font-family: "Lato";
            color: #000000;
            margin-bottom: 10px;

            &:nth-child(4n) {
                margin-right: 0;
            }

            &:nth-child(4n+1) {
                clear: both;
            }

            .title-event {
                position: relative;
                font-weight: 700;
                line-height: 24px;
                padding: 5px 0;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: -17px;
                    bottom: 0;
                    width: 5px;
                    background: #9e0b0f;
                }

                h5 {
                    font-size: 14px;
                    font-weight: 700;
                    color: #000000;
                    line-height: 18px;
                    margin-bottom: 3px;
                }

                span {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;
                    display: block;
                }
            }

            .desc-event {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
            }
        }
    }
}

.calendar-event {
    .ui-datepicker {
        width: 100%;
        font-family: "Lato";

        .ui-datepicker-header {
            @include borderRadius(0);
            padding: 0;
            border: none;
            margin-bottom: 45px;
        }

        .ui-datepicker-prev,
        .ui-datepicker-next {
            width: 200px;
            height: 40px;
            @include borderRadius(0);
            display: block;
            top: 0;

            span {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                text-indent: 0;
                margin: 0;
                width: 100%;
                height: 40px;
                display: inline-block;
                padding-top: 11px;
                @include boxSizing(border-box);
                font-size: 16px;
                color: #8d8c8f;
                font-weight: 400;
                text-transform: uppercase;
            }
        }

        .ui-datepicker-prev {
            left: 0;

            &:before {
                content: "";
                width: 9px;
                height: 12px;
                background: url('../images/material/arr-calender.png') no-repeat top;
                position: absolute;
                top: 13px;
                left: 0;
            }

            span {
                padding-left: 25px;
            }

            &.ui-datepicker-prev-hover {
                border: none;
                font-weight: 400;
            }
        }

        .ui-datepicker-next {
            text-align: right;
            right: 0;

            &:before {
                content: "";
                width: 9px;
                height: 12px;
                background: url('../images/material/arr-calender.png') no-repeat bottom;
                position: absolute;
                top: 13px;
                right: 0;
            }

            span {
                padding-right: 25px;
            }

            &.ui-datepicker-next-hover {
                border: none;
                font-weight: 400;
            }
        }

        .ui-state-hover,
        .ui-widget-content .ui-state-hover,
        .ui-widget-header .ui-state-hover,
        .ui-state-focus,
        .ui-widget-content .ui-state-focus,
        .ui-widget-header .ui-state-focus,
        .ui-button:hover,
        .ui-button:focus {
            border: none;
            background: transparent;
            font-weight: 400;
        }

        .ui-widget-header {
            background: transparent;
        }

        .ui-datepicker-prev-hover,
        .ui-datepicker-next-hover {
            top: 0;
            background: blue;
        }

        .ui-datepicker-title {
            font-size: 36px;
            color: #333;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 40px;
            height: 40px;

            .ui-datepicker-month {
                font-weight: 700;
            }
        }

        table {
            border: none;

            thead {
                border-bottom: 1px solid #002d72;
                text-transform: uppercase;
                color: #002d72;
            }

            th {
                color: #002d72;
                font-size: 16px;
            }

            th,
            td {
                border: none;
                background: transparent;
                text-align: center;

                &:nth-child(2n) {
                    background: transparent;
                }

                a {
                    text-align: center;
                }

                span {
                    text-align: center;
                }
            }

            td {
                padding: 11px;
                font-size: 20px;
                font-weight: 400;
                color: #3b3942;
                width: 136px;
                height: 66px;
                position: relative;
                border-right: 1px solid #b2c0d4;
                border-bottom: 1px solid #b2c0d4;

                a {
                    @include transition(0.1s all ease-out);
                }

                >span {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    color: #999;
                    font-size: 10px;
                    text-align: center;
                    display: inline-block;
                    padding-bottom: 6px;
                }

                &:last-child {
                    border-right: none;
                }

                &.ui-datepicker-current-day {
                    background: #9e0b0f;
                    border-color: #9e0b0f;

                    >span {
                        display: none;
                    }
                }

                &.wp_ev {
                    position: relative;

                    &:before {
                        content: "";
                        width: 17px;
                        height: 17px;
                        background: #9e0b0f;
                        @include borderRadius(100%);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        @include transform(translate(-50%, -50%));
                    }

                    .ui-state-default {
                        &.ui-state-active {
                            margin-bottom: 0;
                            /*&:before{background: $white;}*/
                        }
                    }
                }
            }

            tr {
                border: none;
            }
        }
    }

    .ui-widget.ui-widget-content {
        border: none;
        padding: 40px 16px;
        @include boxSizing(border-box);
    }

    .ui-state-default,
    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default,
    .ui-button,
    .ui-button.ui-state-disabled:hover,
    .ui-button.ui-state-disabled:active {
        border: 2px solid transparent;
        background: transparent;
        width: auto;
        height: auto;
        display: inline-block;
        float: none;
        @include boxSizing(border-box);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        font-size: 16px;
        text-align: right;
    }

    .ui-state-highlight,
    .ui-widget-content .ui-state-highlight,
    .ui-widget-header .ui-state-highlight {
        border-color: transparent;
        background: #1b2287;
        color: #fff;
    }

    .ui-state-active,
    .ui-widget-content .ui-state-active,
    .ui-widget-header .ui-state-active,
    a.ui-button:active,
    .ui-button:active,
    .ui-button.ui-state-active:hover {
        background: #9e0b0f;
        border-color: #9e0b0f;
        font-weight: 400;
        color: $white;
    }
}

#calendar {
    margin-top: 30px;

    table {
        margin: 0;
    }

    .fc-toolbar.fc-header-toolbar {
        position: relative;
    }

    .fc-button-group {
        .fc-prev-button {
            position: absolute;
            left: 0;
            top: 0;
        }

        .fc-next-button {
            position: absolute;
            left: 40px;
            top: 0;
        }
    }

    td {
        width: auto;

        &.fc-day-top {
            padding: 0;

            a {
                color: #3b3942;
            }

            &.fc-today {
                background: #1b2287;

                a {
                    color: #fff;
                }
            }
        }

        &.fc-event-container {
            padding: 5px;
        }
    }

    .fc-scroller {
        height: auto !important;
    }

    .fc-event {
        background: #9e0b0f;
        border: none;
        padding: 2px 10px;
        @include boxSizing(border-box);
        font-family: "f_f2";
        font-size: 14px;
    }

    .fc-center {
        h2 {
            color: #000;
            ;
            font-family: "lato";
            font-style: normal;
            text-transform: uppercase;
        }
    }

    .fc-widget-header {
        border: none;

        table,
        tr,
        thead,
        tbody {
            border: none;
        }

        &:after {
            content: '';
            clear: both;
            display: block;
        }

        th {
            height: 50px;
            vertical-align: middle;
            padding: 0;
            color: #002d72;
            border: 1px solid rgba(0, 45, 114, 0.3);
            border-top-color: #446598;
            border-bottom-color: #446598;

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }

            &:after {
                content: '';
                clear: both;
                display: block;
            }
        }
    }
}

/* popup
----------------------------------------------------------------------------------------------*/

.wrap_popup {
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999999;
    display: none;
    overflow-y: auto;

    .content_pop {
        background: #fff;
        position: relative;
        z-index: 8;
        padding: 0;
        width: 970px;
        margin: 5% auto;
        height: 400px;

        .close {
            font-size: 20px;
            display: block;
            position: absolute;
            width: 50px;
            height: 50px;
            right: -50px;
            top: 0;
            background: url('../images/material/ico-close.png')no-repeat center #1b2287;
        }

        .video {
            iframe {
                width: 100%;
                height: 450px;
            }
        }
    }
}



/* footer
----------------------------------------------------------------------------------------------*/
footer {
    .top-footer {
        background: #1b2287;
        padding: 60px 0 50px;

        .top-info {
            width: 100%;

            .logo {
                float: left;
                margin-right: 208px;

                &:after {
                    display: block;
                    content: '';
                    clear: both;
                }

                img {
                    height: 100px;
                    width: 315px;
                }
            }

            .part {
                height: 80px;
                width: 220px;
                float: left;
                color: #fff;
                border-left: 3px solid #fff;
                padding-left: 57px;

                &:last-child {
                    border-left: 0;
                    padding-top: 15px;
                    margin-right: 145px;
                }

                &:after {
                    display: block;
                    content: '';
                    clear: both;
                }

                h6 {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 20px;
                }

                p {
                    font-size: 16px;
                    font-family: $f_f2;
                    color: #fff;

                    a {
                        color: #fff;
                    }
                }

            }

            .ico-sosmed {
                float: right;
                padding-top: 35px;

                &:after {
                    display: block;
                    content: '';
                    clear: both;
                }

                a {
                    display: inline-block;
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &.ic_twt {
                        background: url('../images/material/ico-twt.png')no-repeat center;
                        width: 32px;
                        height: 32px;
                    }

                    &.ic_fb {
                        background: url('../images/material/ico-fb.png')no-repeat center;
                        width: 32px;
                        height: 32px;
                    }

                    &.ic_ig {
                        background: url('../images/material/ico-ig.png')no-repeat center;
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }
    }

    .bot-footer {
        background: #fff;
        padding: 20px 0 25px;

        .ic-partner {
            float: left;

            &:after {
                display: block;
                content: '';
                clear: both;
            }
        }

        .desc {
            float: right;
            text-align: right;

            &:after {
                display: block;
                content: '';
                clear: both;
            }

            .nav-bot {
                &:after {
                    display: block;
                    content: '';
                    clear: both;
                }

                .item {
                    border-left: 1px solid #ccc;
                    font-size: 12px;
                    float: left;
                    padding-left: 15px;
                    margin-left: 15px;
                    line-height: 15px;

                    &:first-child {
                        padding-left: 0;
                        border: none;
                    }
                }
            }
        }
    }
}